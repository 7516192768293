import { SplitFilterStateType } from "src/context/section-context/types"
import { FilterStateType, SectionNameEnum, PropType } from "src/types"

export class SessionStorageManager {
  constructor() {}

  private getValue(key: string) {
    const value = window.sessionStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  private setValue(key: string, value: unknown) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }

  public getSectionFiltersKey(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
  ) {
    return `${orgId}_${portfolioId}_${sectionId}`
  }

  public getSectionFilters(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
  ): PropType<FilterStateType, "appliedFilters"> | null {
    return this.getValue(
      this.getSectionFiltersKey(orgId, portfolioId, sectionId),
    )
  }

  public setSectionFilters(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
    appliedFilters: PropType<FilterStateType, "appliedFilters">,
  ) {
    this.setValue(
      this.getSectionFiltersKey(orgId, portfolioId, sectionId),
      appliedFilters,
    )
  }

  public clear() {
    window.sessionStorage.clear()
  }

  getSectionGroupByState(
    orgId: number,
    portfolioId: number,
    sectionId: string,
  ) {
    return this.getValue(`${orgId}-${portfolioId}-${sectionId}-groupBy`)
  }

  setSectionGroupByState(
    orgId: number,
    portfolioId: number,
    sectionId: string,
    groupByState: string | undefined,
  ) {
    this.setValue(`${orgId}-${portfolioId}-${sectionId}-groupBy`, groupByState)
  }

  getSectionSplitState(orgId: number, portfolioId: number, sectionId: string) {
    return this.getValue(`${orgId}-${portfolioId}-${sectionId}-split`)
  }

  setSectionSplitState(
    orgId: number,
    portfolioId: number,
    sectionId: string,
    splitState: {
      splitFilterId?: string
      splitFilterState: SplitFilterStateType
    },
  ) {
    this.setValue(`${orgId}-${portfolioId}-${sectionId}-split`, splitState)
  }
}

export default SessionStorageManager
